<template>
  <div>
    <div class="head_div">
      <img @click="click_logo" src="@/assets/img/index/logo2.png" alt="" class="logo" />
      <div class="head_menu_div">
        <div
          :class="menu_index == i?'head_menu_title2':'head_menu_title'"
          @click="click_menu(item, i)"
          v-for="(item, i) in menu_list"
          :key="i"
        >
          {{ item.title }}
        </div>
        <!-- <div class="head_menu_title"></div>
        <div class="head_menu_title">加入我们</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { open_new_page } from "@/utils/http";
export default {
  name: "Head",
  props: ["menu_index"],
  data() {
    return {
      menu_list: [
        { title: "首页", path: "/" },
        { title: "企业荣誉", path: "/honor" },
        { title: "关于我们", path: "/about_us" },
      ],
    };
  },
  methods: {
    click_menu(item, i) {
      open_new_page(item.path, "_self");
    },

    click_logo() {
      open_new_page("/");
    },
  },
};
</script>

<style scoped>
.logo {
  height: 65px;
  padding: 10px;
  cursor: pointer;
}
.head_menu_title {
  margin-right: 40px;
  font-size: 17px;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  padding-bottom: 5px;
  border-bottom:5px solid #03295500;

}

.head_menu_title2 {
  margin-right: 40px;
  font-size: 17px;
  color: #fff;
  font-weight: 600;
  padding-bottom: 5px;
  border-bottom:4px solid #fff;
  cursor: pointer;
}

.head_menu_title:hover {
  color: #032955;
}

.head_menu_div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 100px;
}
.news_title2 {
  display: flex;
  align-items: center;
  padding-left: 10%;
  padding-bottom: 20px;
  justify-content: flex-start;
  font-size: 24px;
  color: #333;
  font-family: "dingding03";
}

.logo3 {
  height: 65px;
  padding: 10px;
}

.head_div {
  height: 60px;
  background-color: #9fcaff25;
  width: 100vw;
  position: absolute;
  z-index: 999;
  padding: 0 20px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
}
</style>
