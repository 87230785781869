<template>
  <div ref="scrollContainer" @scroll="handleScroll" class="pagemain">
    <div class="video_div">
      <Head v-show="scrolled<400" class="" ref="head" :menu_index="0"></Head>

      <video
        style="width: 100%; height: 100%"
        class="video2"
        autoplay
        muted
        playsinline
        loop
        id="mp41"
      ></video>
      <!-- <div class="video_hint">
        客户为根、以人为本、专业为上、服务为先
      </div> -->
    </div>
    <div class="card1">做最受依赖的人才和就业服务专家</div>

    <div class="menu_card1">
      <div class="news_title2">
        <div class="dito"></div>
        最新动态
      </div>
      <div class="menu_card">
        <div
          class="news_card"
          v-for="(item, i) in news_list"
          :key="i"
          @click="click_news(item, i)"
        >
          <img :src="item.img" alt="" class="news_img" />
          <div class="news_title">
            <div class="news_date">
              {{ item.date }}
            </div>
            <p class="news_title_weight">
              {{ item.title }}
            </p>
            <div class="yuedu_yuanwen">
              <span class="ydyw"> 阅读全文 </span>
              <span>
                <Icon type="ios-arrow-forward"></Icon>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>

    
  </div>
</template>

<script>
import videojs from "video.js";
import { request, open_new_page } from "../../utils/http";
import Head from "../common/head.vue";
import Footer from "../common/footer.vue";

export default {
  components: {
    Head,
    Footer
  },
  data() {
    return {
      scrolled: 0,
      show_menu: true,
      video_url: "https://static.daxiangsys.com/dx-short-link/group_ows/01.mp4",
      news_list: [
        // {
        //   title: "凝心聚力，同行致远昆明市委常委、副市长屈从文率队来长调研",
        //   img:
        //     "https://mmbiz.qpic.cn/sz_mmbiz_jpg/NYj69lc2UaxkE5iabWKrPiaOiaw6H5YrOQqjk3Acsfz4xZibiawuzr1lLUzKNMwCfL1szyWl3FyA3MibqdNNb8EqXurw/640?wx_fmt=jpeg&from=appmsg&wxfrom=5&wx_lazy=1&wx_co=1",
        //   url: "https://mp.weixin.qq.com/s/EBvHQCoWi9oqPQsq5j6Wzw",
        // },
      ],
    };
  },
  mounted() {
    this.play_video();
    this.get_news_list();
    // window.addEventListener("scroll", this.handleScroll, true);
  },
  updated() {},
  beforeDestroy() {},
  methods: {
    get_news_list() {
      let obj = {};
      var that = this;
      request("post", process.env.VUE_APP_HOST + "/api_export/ows/news_list", obj).then(
        (res) => {
          console.log(res);
          if (res.data.code == 0) {
            console.log(res.data);
            that.news_list = res.data.data.news_list;
          } else {
            that.$Message.error(res.data.msg);
          }
        }
      );
    },

    click_news(item, i) {
      open_new_page(item.url);
    },

    handleScroll(e) {
      let scrolled = this.$refs.scrollContainer.scrollTop;
      // console.log(scrolled);
      this.scrolled = scrolled;
      // if (scrolled > 200) {
      //   this.show_menu = false;
      // } else {
      //   this.show_menu = true;
      // }
      // //滚动条触发事件
      // console.log(e);
      // if (
      //   e.srcElement.scrollTop + e.srcElement.clientHeight ==
      //   e.srcElement.scrollHeight
      // ) {
      //   console.log("嘿嘿我在底部触发了");
      // }
    },
    play_video() {
      var url = this.video_url;
      const player = videojs("mp41", { autoplay: true });
      player.src(url);
      player.on("ended", () => {});
    },
  },
};
</script>

<style scoped>
.dito {
  height: 20px;
  width: 10px;
  background: #0466dd;
  border-radius: 3px;
  margin-right: 10px;
}

.news_card {
  background: #fff;
  border-radius: 5px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  overflow: hidden;
  cursor: pointer;
}

.news_card:hover {
  box-shadow: 0 0 6px #89bfd3;
}

.news_date {
  text-align: right;
  margin-bottom: 10px;
  color: #bbbbbb;
  font-size: 13px;
  font-weight: 300;
}

.news_title_weight {
  height: 80px;
  text-indent: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  word-break: break-all;
}

.news_title {
  text-indent: 1em;
  font-size: 14px;
  width: 270px;
  height: 135px;
  text-align: left;
  font-weight: 300;
  color: #888;
  padding: 10px 10px 30px 10px;
}

.news_img {
  width: 270px;
  height: 150px;
}

.slogn {
  font-family: "dingding02";
  font-size: 18px;
  color: #dcdcdc;
  padding-left: 20px;
}

a {
  text-decoration: none;
  color: inherit;
}

.yuedu_yuanwen {
  font-size: 12px;
  color: #ebebeb;
  text-align: right;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.beian {
  margin-top: 30px;
  font-size: 13px;
  color: #ccc;
  padding-top: 30px;
  border-top: 1px solid #e3e3e3;
}

.ydyw {
  position: relative;
  right: -25px;
}

.footer {
  padding: 50px 0 30px 0;
  background: #f6f6f6;
}

.card1 {
  padding: 15px 0;
  font-size: 30px;
  letter-spacing: 0.2em;
  margin: 0px 0 5px 0;
  color: #fff;
  background: #0466dd;
  font-family: "dingding01";
}

.head_menu_title {
  margin-right: 40px;
  font-size: 16px;
  color: #f3f3f3;
  font-weight: 600;
}

.video2 {
  -webkit-backface-visibility: hidden;
}
.pagemain {
  height: 100vh;
  overflow: auto;
}

.head_menu_div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 100px;
}

.fotter_logo_right {
  display: flex;
  align-items: center;
}

.guanzhu {
  font-size: 22px;
  color: #ccc;
}

.gzh_img {
  padding: 3px;
  background: #fff;
  border-radius: 5px;
  width: 100px;
  height: 100px;
}

.fotter_logo {
  display: flex;
  padding: 0 10%;
  justify-content: space-between;
}

.menu_card1 {
  background: #efefef;
  padding-top: 40px;
  padding-bottom: 50px;
}

.menu_card {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.video_hint {
  color: #244267;
  background: #e9e9e900;
  font-size:46px;
  font-weight: 600;
  font-family: "dingding02";
  width: 100vw;
  height: 100px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40%;
  z-index: 9999;
}

.logo {
  height: 65px;
  padding: 10px;
}

.news_title2 {
  display: flex;
  align-items: center;
  padding-left: 10%;
  padding-bottom: 20px;
  justify-content: flex-start;
  font-size: 24px;
  color: #333;
  font-family: "dingding06";
}

.logo3 {
  height: 65px;
  padding: 10px;
}

.head_div {
  height: 60px;
  background-color: #0466dd25;
  width: 100vw;
  position: absolute;
  padding: 0 20px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
}

.video_div {
  padding: 0;
  margin: 0;
}

#mp41 {
  object-fit: fill;
}
</style>
